import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndpoint from "../../config/environment";

export const generalServerApi = createApi({
  reducerPath: "generalServerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ["YouTube", "Notifications"],

  endpoints: (builder) => ({
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `user/client/${id}`,
        method: "DELETE",
      }),
    }),

    deleteCoach: builder.mutation({
      query: ({ id, payload }) => ({
        url: `user/coach/${id}`,
        method: "DELETE",
        body: payload,
      }),
    }),
    getPodcasts: builder.query({
      query: () => `/resources/podcast`,
    }),
    // Youtube  Queries
    getYoutubeVideos: builder.query({
      query: ({ pageToken }) =>
        `resources/youtube-videos?perPage=12&pageToken=${pageToken ? pageToken : ""}`,
      providesTags: ["YouTube"],
    }),
    
    // get Notifications
    getNotifications: builder.query({
      query: (id) => `notification/${id}?role=admin`,
      providesTags: ["Notifications"],
    }),
    // mark Notifications
    markNotifications: builder.mutation({
      query: (payload) => ({
        url: "notifications",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Notifications"],
    }),
    // mark all Notifications
    markAllNotifications: builder.mutation({
      query: (payload) => ({
        url: "notifications/mark",
        method: "POST",
        body: payload,
      }),
        invalidatesTags: ["Notifications"],
      }),
    // get Notifications Count
    getNotificationsCount: builder.query({
      query: (id) => `notification-count/${id}?role=admin`,
      providesTags: ["Notifications"],
    }),

    getMeetingDetails: builder.query({
      query: (id) => `zoom/meeting/${id}`,
    }),
  }),
});

export const {
  useDeleteClientMutation,
  useDeleteCoachMutation,
  useGetPodcastsQuery,
  useGetYoutubeVideosQuery,
  useGetNotificationsQuery,
  useMarkNotificationsMutation,
  useGetNotificationsCountQuery,
  useLazyGetMeetingDetailsQuery,
  useMarkAllNotificationsMutation,
} = generalServerApi;
