import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoint from '../../config/environment';

export const uploadServerApi = createApi({
  reducerPath: 'uploadServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    uploadOne: builder.mutation({
      query: (payload) => ({
        url: "upload-one",
        method: "POST",
        body: payload
      }),
    }),
  })
})


