export default function index({ onClick }: any) {
  return (
    <div>
      <div className="bg-gray-100 h-screen">
        <div className="bg-white p-6  md:mx-auto">
          <svg
            viewBox="0 0 26 26"
            className="text-green-600 w-20 h-20 mx-auto my-6"
          >
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            ></path>
          </svg>
          <div className="text-center">
            <h3 className="md:text-3xl text-base text-gray-900 font-semibold text-center">
              Success
            </h3>
            <p className="text-gray-600 my-2 md:text-xl">
              You are successfully logged In
            </p>

            <div className="py-10 text-center">
              <button
                type="button"
                onClick={onClick}
                className="text-white rounded-[1rem] bg-[#2165a2] border-solid border-[white]  !mt-0 border-[0.2rem] text-[1.4rem] font-medium w-[16rem] h-[3.6rem]"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
