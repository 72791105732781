import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import MobileLogo from "../../components/MobileLogo";
import Spinner from "../../components/Spinner";
import { signInAsync } from "../../store/reducers/auth.reducer";
import classes from "../../styles/LogonStyle";

interface IFormInputs {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required"),
  password: yup.string().min(6).required("Password is Required"),
});

const Form = () => {
  const [isPassword, setIsPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    dispatch(signInAsync(data.email, data.password))
      .then(() => {
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <Box component="div" sx={classes.LogBlk}>
      <Box component="div" sx={classes.fromWrapper}>
        <MobileLogo />

        <Typography variant="h2" sx={classes.formTitle}>
          Sign In
        </Typography>
        <Typography component="p" sx={classes.subTitle}>
          Hello Welcome to your dashboard.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component="div" sx={classes.form_blk}>
            <Typography
              variant="h6"
              sx={classes.formlabel}
              // className="require"
            >
              Email Address
            </Typography>

            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={classes.logonTextField}
                  variant="outlined"
                  type="email"
                  fullWidth
                  placeholder="Enter your Email Address"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email?.message : null}
                />
              )}
            />
          </Box>
          <Box component="div">
            <Typography
              variant="h6"
              sx={classes.formlabel}
              // className="require"
            >
              Password
            </Typography>

            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={(classes.logonTextField, classes.logonTextField)}
                  variant="outlined"
                  type={isPassword ? "password" : "text"}
                  fullWidth
                  placeholder="Enter your Password"
                  error={!!errors.password}
                  helperText={errors.password ? errors.password?.message : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          size="large"
                          onClick={() => setIsPassword(!isPassword)}
                        >
                          {isPassword ? (
                            <VisibilityIcon
                              sx={{
                                fontSize: "2.4rem",
                                color: "#312948",
                              }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              sx={{
                                fontSize: "2.4rem",
                                color: "#312948",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Typography sx={classes.forgetPass}>
            <Link
              to="/reset-password"
              style={{ textDecoration: "none", color: "#2B2E34" }}
            >
              Forgot Password
            </Link>
          </Typography>

          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={loading ? classes.disabled : classes.logonBtn}
          >
            {loading ? <Spinner /> : "Login"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Form;
