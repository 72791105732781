import { Box } from "@mui/material";
import LogonBackground from "../assets/images/logonSidebar.jpg";
import logonLogo from "../assets/images/logo-color.png";
import classes from "../styles/LogonStyle";
import { useGetBannerByTypeQuery } from "../services/admin";

const LogonSide = () => {
  const id = "ADMIN_LOGIN";
  const { data } = useGetBannerByTypeQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Box
      component="div"
      style={{
        backgroundImage: data?.data?.result?.imageUrl
          ? "url(" + data?.data?.result?.imageUrl + ")"
          : "url(" + LogonBackground + ")",
      }}
      sx={classes.logonSidebar}
    >
      <Box component="div" sx={classes.logonLogo}>
        <img src={logonLogo} alt="" />
      </Box>
    </Box>
  );
};

export default LogonSide;
