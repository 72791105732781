import logonLogo from "../assets/images/logonLogo.svg";

const MobileLogo = () => {
	return (
		<div className="w-96 mx-auto mb-12 md:hidden">
			<img src={logonLogo} alt="" />
		</div>
	);
};

export default MobileLogo;
