import { styled } from "@mui/material/styles";
import theme from "../theme";

export const ProfileBox = styled("div")(() => ({
	display: "flex",
	gap: "2.4rem",
	alignItems: "center",
}));

export const Profile = styled("div")(() => ({
	width: "5.7rem",
	minWidth: "5.7rem",
	height: "5.7rem",
	borderRadius: "1.4rem",
	overflow: "hidden",
}));
const classes = {
	header: {
		paddingLeft: "30.4rem",
		background: "#fff",
		boxShadow: "0 0.2rem 2.5rem rgba(51, 51, 51, 0.1)",
		height: "11.5rem",
		position: "fixed",
		top: 0,
		left: 0,
		zIndex: 15,
		width: "100%",
		justifyContent: "center",
		[theme.breakpoints.down("md")]: {
			paddingLeft: "0",
		},
	},
	bellBadge: {
		" >span": {
			width: "3.1rem",
			Minwidth: "3.1rem",
			height: "3.1rem",
			padding: "0",
			background: "#299AFF",
			border: "0.2rem solid #FBFBFB",
			borderRadius: "1.2rem",
			fontWeight: 400,
			fontSize: "1.4rem",
			lineHeight: "2.1rem",
			color: "#FFFFFF",
		},
	},
	name: {
		fontWeight: 600,
		fontSize: "1.6rem",
		lineHeight: "2.4rem",
		color: "#333333",
	},
	role: {
		fontWeight: 400,
		fontSize: "1.4rem",
		lineHeight: "2.1rem",
		color: "rgba(51, 51, 51, 0.4)",
	},
};

export default classes;
