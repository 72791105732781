import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndpoint from "../../config/environment";

export const adminServerApi = createApi({
  reducerPath: "adminServerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/admin/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: [
    "Courses",
    "Events",
    "Notes",
    "Apparel",
    "Password",
    "Call",
    "Forms",
    "Boarding",
    "Report",
    "ScheduleCall",
    "User",
    "Points",
    "Banner",
  ],

  endpoints: (builder) => ({
    getAllClients: builder.query({
      query: () => "all-clients",
      providesTags: ["Call", "User"],
    }),

    getAllCoaches: builder.query({
      query: () => "all-coaches",
      providesTags: ["Call", "User"],
    }),

    getAllCoachesLeaderboard: builder.query({
      query: () => "coaches-leaderboard",
      providesTags: ["Call", "User"],
    }),

    getUserById: builder.query({
      query: (id) => `user/${id}`,
    }),

    uploadCourse: builder.mutation({
      query: (payload) => ({
        url: "course",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Courses"],
    }),

    updateCourse: builder.mutation({
      query: (payload) => ({
        url: `course/${payload?.id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Courses"],
    }),

    getCourses: builder.query({
      query: () => `course`,
      providesTags: ["Courses"],
    }),

    getSingleCourse: builder.query({
      query: (id) => `course/${id}`,
      providesTags: ["Courses"],
    }),

    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `course/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Courses"],
    }),

    getAllEvents: builder.query({
      query: () => "events",
      providesTags: ["Events"],
    }),
    getEventById: builder.query({
      query: (id) => `events/${id}`,
      providesTags: ["Events"],
    }),

    createEvent: builder.mutation({
      query: (payload) => ({
        url: `events`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Events"],
    }),

    updateEvent: builder.mutation({
      query: ({ id, payload }) => ({
        url: `events/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Events"],
    }),

    removeEventMember: builder.mutation({
      query: ({ payload }) => ({
        url: `events/remove-member`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["Events"],
    }),

    cancelEvent: builder.mutation({
      query: ({ id }) => ({
        url: `events/cancel/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Events"],
    }),

    getAllUsers: builder.query({
      query: () => "all-users",
      providesTags: ["Events"],
    }),
    getAllPoints: builder.query({
      query: () => "/client-score",
      providesTags: ["Points"],
    }),
    //GET ALL COACH NOTES
    getCoachNotes: builder.query({
      query: () => "/coach-notes",
      providesTags: ["Notes"],
    }),
    //GET ALL CLIENT NOTES
    getClientNotes: builder.query({
      query: () => "/client-notes",
      providesTags: ["Notes"],
    }),

    createNote: builder.mutation({
      query: (payload) => ({
        url: "notes/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Notes"],
    }),
    deleteNote: builder.mutation({
      query: (id) => ({
        url: `notes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notes"],
    }),
    getNote: builder.query({
      query: (id) => `/notes/${id}`,
      providesTags: ["Notes"],
    }),
    updateNote: builder.mutation({
      query: ({ id, payload }) => ({
        url: `notes/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Notes"],
    }),

    //  Apparels Apis
    getAllApparels: builder.query({
      query: () => "apparel",
      providesTags: ["Apparel"],
    }),
    createApparel: builder.mutation({
      query: (payload) => ({
        url: "apparel/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Apparel"],
    }),
    deleteApparel: builder.mutation({
      query: (id) => ({
        url: `apparel/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Apparel"],
    }),

    getApparel: builder.query({
      query: (id) => `apparel/${id}`,
      providesTags: ["Apparel"],
    }),
    updateApparel: builder.mutation({
      query: ({ id, payload }) => ({
        url: `apparel/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Apparel"],
    }),

    // Change Password Api
    changePassword: builder.mutation({
      query: (payload) => ({
        url: "change-password",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Password"],
    }),
    // Create Huddle Call
    createHuddleCall: builder.mutation({
      query: (payload) => ({
        url: "call-library/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Call"],
    }),

    createBulkCalls: builder.mutation({
      query: (payload) => ({
        url: "call-library/create-bulk",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Call"],
    }),

    getHuddleCalls: builder.query({
      query: ({ type }) => `call-library?type=${type}`,
      providesTags: ["Call"],
    }),

    deleteHuddleCall: builder.mutation({
      query: (payload) => ({
        url: `call-library/${payload.id}`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["Call"],
    }),
    getCheckinForms: builder.query({
      query: () => "checkinform",
      providesTags: ["Forms"],
    }),
    getCheckinForm: builder.query({
      query: (id) => `checkinform/${id}`,
      providesTags: ["Forms"],
    }),
    checkinFormStatus: builder.mutation({
      query: ({ id, payload }) => ({
        url: `checkinform/update/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Forms"],
    }),
    deleteCheckInForm: builder.mutation({
      query: (id) => ({
        url: `checkinform/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Forms"],
    }),
    createCheckinForm: builder.mutation({
      query: (payload) => ({
        url: "checkinform/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Forms"],
    }),
    updateCheckinForm: builder.mutation({
      query: ({ id, payload }) => ({
        url: `checkinform/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Forms"],
    }),
    getOnBoardingForms: builder.query({
      query: () => "onboarding",
      providesTags: ["Boarding"],
    }),
    getOnBoardingForm: builder.query({
      query: (id) => `onboarding/${id}`,
      providesTags: ["Boarding"],
    }),
    OnBoardingFormStatus: builder.mutation({
      query: ({ id, payload }) => ({
        url: `onboarding/update/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Boarding"],
    }),
    deleteOnBoardingForm: builder.mutation({
      query: (id) => ({
        url: `onboarding/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Boarding"],
    }),
    createOnBoardingForm: builder.mutation({
      query: (payload) => ({
        url: "onboarding/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Boarding"],
    }),
    updateOnBoardingForm: builder.mutation({
      query: ({ id, payload }) => ({
        url: `onboarding/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Boarding"],
    }),

    // Usage Reports APis Integrations

    getClientUsersReport: builder.mutation({
      query: (payload) => ({
        url: "client-users-report",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Report"],
    }),
    getClientActionItemsReport: builder.mutation({
      query: (payload) => ({
        url: "client-actionitem-report",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Report"],
    }),
    getCoachUsersReport: builder.mutation({
      query: (payload) => ({
        url: "coach-users-report",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Report"],
    }),
    getCoachActionItemsReport: builder.mutation({
      query: (payload) => ({
        url: "coach-actionitem-report",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Report"],
    }),
    getCompleteChallengeReport: builder.mutation({
      query: (payload) => ({
        url: "complete-challenge-report",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Report"],
    }),
    getChampionshipReport: builder.mutation({
      query: (payload) => ({
        url: "championship-report",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Report"],
    }),

    // Create Call
    createScheduleCall: builder.mutation({
      query: (payload) => ({
        url: "call/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ScheduleCall"],
    }),

    getScheduleCalls: builder.query({
      query: () => `call`,
      providesTags: ["ScheduleCall"],
    }),
    deleteScheduleCall: builder.mutation({
      query: (id) => ({
        url: `call/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ScheduleCall"],
    }),
    deleteGoogleScheduleCall: builder.mutation({
      query: (payload) => ({
        url: `call/${payload.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ScheduleCall"],
    }),
    getByIdScheduleCall: builder.query({
      query: (id) => `call/${id}`,
      providesTags: ["ScheduleCall"],
    }),
    updateScheduleCall: builder.mutation({
      query: ({ id, payload }) => ({
        url: `call/update/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["ScheduleCall"],
    }),
    // Update Contract Date api
    updateContractEndDate: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `update-contractEndate/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Call"],
    }),
    //Upload Banner

    createBanner: builder.mutation({
      query: (payload) => ({
        url: "banner/create",
        method: "POST",
        body: payload,
      }),
    }),

    // Update Banner
    updateBanner: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `banner/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    //get All Banners

    getBanners: builder.query({
      query: () => "banner",
      providesTags: ["Banner"],
    }),

    //Delete Banner

    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `banner/${id}`,
        method: "DELETE",
      }),
    }),
    //get Banner By Type

    getBannerByType: builder.query({
      query: (type) => `banner/${type}`,
    }),

    deleteEvent: builder.mutation({
      query: ({ id }) => ({
        url: `events/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),

    updateUserById: builder.mutation({
      query: ({ id, payload }) => ({
        url: `update-user/${id}`,
        method: "PATCH",
        body: payload,
      }),
    }),

    updateScore: builder.mutation({
      query: ({ payload }) => ({
        url: `score-points`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),

    getScorePoints: builder.query({
      query: () => "score-points",
      providesTags: ["User"],
    }),

    assignCoach: builder.mutation({
      query: ({ payload }) => ({
        url: `assign-coach`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),

    updateUserWinRating: builder.mutation({
      query: ({ payload }) => ({
        url: `/update-user-winrating/${payload.userId}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["User"],
    }),

    createGHLEvent: builder.mutation({
      query: (payload) => ({
        url: `ghl-events`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Events"],
    }),

    updateGHLEvent: builder.mutation({
      query: (payload) => ({
        url: `ghl-update-events`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Events"],
    }),

    createGHLCourse: builder.mutation({
      query: (payload) => ({
        url: `ghl-course`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Courses"],
    }),

    getClientCheckInHistory: builder.query({
      query: (id) => `checkinform/history/all/${id}`,
      providesTags: ["Forms"],
    }),

  }),
});

export const {
  useGetAllClientsQuery,
  useGetAllCoachesQuery,
  useGetUserByIdQuery,
  useUploadCourseMutation,
  useGetCoursesQuery,
  useDeleteCourseMutation,
  useGetAllEventsQuery,
  useGetAllUsersQuery,
  useGetAllPointsQuery,
  useCreateNoteMutation,
  useGetCoachNotesQuery,
  useDeleteNoteMutation,
  useGetNoteQuery,
  useUpdateNoteMutation,
  useGetEventByIdQuery,
  useUpdateEventMutation,
  useRemoveEventMemberMutation,
  useCancelEventMutation,
  useGetAllApparelsQuery,
  useCreateApparelMutation,
  useDeleteApparelMutation,
  useGetApparelQuery,
  useUpdateApparelMutation,
  useChangePasswordMutation,
  useCreateHuddleCallMutation,
  useDeleteHuddleCallMutation,
  useGetHuddleCallsQuery,
  useGetCheckinFormsQuery,
  useGetCheckinFormQuery,
  useCheckinFormStatusMutation,
  useDeleteCheckInFormMutation,
  useCreateCheckinFormMutation,
  useUpdateCheckinFormMutation,
  useCreateOnBoardingFormMutation,
  useDeleteOnBoardingFormMutation,
  useGetOnBoardingFormQuery,
  useGetOnBoardingFormsQuery,
  useUpdateOnBoardingFormMutation,
  useOnBoardingFormStatusMutation,
  useGetChampionshipReportMutation,
  useGetClientActionItemsReportMutation,
  useGetClientUsersReportMutation,
  useGetCoachActionItemsReportMutation,
  useGetCoachUsersReportMutation,
  useGetCompleteChallengeReportMutation,
  useCreateScheduleCallMutation,
  useDeleteScheduleCallMutation,
  useDeleteGoogleScheduleCallMutation,
  useGetByIdScheduleCallQuery,
  useGetScheduleCallsQuery,
  useUpdateScheduleCallMutation,
  useUpdateContractEndDateMutation,
  useGetClientNotesQuery,
  useCreateBannerMutation,
  useUpdateBannerMutation,
  useGetBannersQuery,
  useDeleteBannerMutation,
  useGetBannerByTypeQuery,
  useDeleteEventMutation,
  useUpdateUserByIdMutation,
  useUpdateScoreMutation,
  useGetScorePointsQuery,
  useAssignCoachMutation,
  useUpdateUserWinRatingMutation,
  useCreateBulkCallsMutation,
  useGetAllCoachesLeaderboardQuery,
  useCreateGHLEventMutation,
  useUpdateGHLEventMutation,
  useGetClientCheckInHistoryQuery,
  useGetSingleCourseQuery,
  useUpdateCourseMutation
} = adminServerApi;

export function isFetchBaseQueryError(error: unknown): error is {
  status: number;
  data: {
    message: string;
    status: number;
    success: boolean;
    data: any;
  };
} {
  return typeof error === "object" && error != null && "status" in error;
}
