import theme from "../theme";

const classes = {
  Logon: {
    display: "flex",
    alignItems: "stretch",
    padding: 0,
    background: "#fff",
    fontFamily: "Roboto",
  },
  logonSidebar: {
    width: "40%",
    // height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logonLogo: {
    maxWidth: "43.2rem",
  },

  LogBlk: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    minHeight: "100vh",
    padding: "6rem 0",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "6rem",
    },
  },
  LogBlkManageUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
  },
  fromWrapper: {
    width: "70%",
    margin: "0 auto",
  },
  ContractEndDate: {
    display: "flex",
    justifyContent: "start"
  },
  fromWrapperDone: {
    width: "100%",
    maxWidth: "58rem",
    margin: "0 auto",
    textAlign: "center",
  },
  fromWrapperDoneTypo: {
    fontSize: "2.4rem !important",
    lineHeight: "3.2rem !important",
  },
  formTitle: {
    fontWeight: "700",
    fontSize: "4rem",
    lineHeight: "6rem",
  },
  subTitle: {
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    color: "#444444",
    marginBottom: "4.6rem",
  },
  subTitleConfirmPassword: {
    fontSize: "2.4rem",
    lineHeight: "2.7rem",
    color: "#444444",
    marginBottom: "2.9rem",
    marginTop: "2.7rem",
  },
  logonTextField: {
    //  background: "rgba(162, 163, 172, 0.04);",
    "& .MuiInputBase-input": {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "3rem",
      color: "#2B2E34",
      width: "100%",
      padding: "1.5rem 2rem",
      // textTransform: "capitalize",
      // "&.Mui-disabled": {
      //   color: "#2B2E34",
      //   "-webkitTextFillColor": "#2B2E34",
      // },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.1rem solid #A8A8A8",
      borderRadius: "1.6rem",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "error",
      fontSize: "1.75rem",
    },
  },
  otpTextField: {
    "& .MuiInputBase-input": {
      fontWeight: 700,
      fontSize: "5rem",
      lineHeight: "3rem",
      color: "#2B2E34",
      padding: "1.5rem 2rem",
      width: "6rem",
      height: "6rem",
      textAlign: "center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.1rem solid #A8A8A8",
    },
  },
  formlabel: {
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    color: "#312948",
    marginBottom: "0.6rem",
  },
  formlabelReset: {
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    color: "#333333",
    marginBottom: "2.2rem",
    marginTop: "4.7rem",
  },

  formlabelConfirmPassword: {
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    color: "#333333",
    marginBottom: "2.2rem",
  },

  formlabelVerification: {
    fontWeight: 400,
    fontSize: "2.4rem",
    lineHeight: "3.6rem",
    color: "#333333",
    marginBottom: "4.9rem",
    marginTop: "2.7rem",
  },
  form_blk: {
    marginBottom: "2rem",
  },
  passIcon: {
    fontSize: "2.4rem",
    color: "#312948",
  },
  forgetPass: {
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "#2B2E34",
    textAlign: "right",
    marginTop: "1.1rem",
  },
  logonBtn: {
    width: "100%",
    fontWeight: 500,
    fontSize: "2rem",
    lineHeight: "3rem",
    textTransform: "capitalize",
    height: "6rem",
    marginTop: "4rem",
    borderRadius: "1rem",
    boxShadow: "none",
  },
  disabled: {
    width: "100%",
    background: "grey",
    fontWeight: 500,
    fontSize: "2rem",
    lineHeight: "3rem",
    textTransform: "capitalize",
    height: "6rem",
    marginTop: "4rem",
    border: "0.1rem solid #A8A8A8",
    borderRadius: "1rem",
    boxShadow: "none",
  },
  resetEmail: {
    fontWeight: 400,
    fontSize: "2.2rem",
    lineHeight: "3.6rem",
    color: "#333333",
    marginTop: "3.1rem",
  },
  wrongNumberLabel: {
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "#333333",
    marginTop: "4.9rem",
  },
  resendCodeLabel: {
    fontWeight: 500,
    fontSize: "2.4rem",
    lineHeight: "3.6rem",
    color: "#333333",
    marginTop: "2rem",
  },
  donePic: {
    maxWidth: "19.4rem",
    maxHeight: "19.4rem",
    margin: "0 auto",
    marginBottom: "4rem",
  },
  createFormBtn: {
    width: "100%",
    fontWeight: 600,
    fontSize: "1.8rem",
    lineHeight: "3rem",
    textTransform: "capitalize",
    height: "6rem",
    borderRadius: "1rem",
    boxShadow: "none",
  },
  editUserMain: {
    backgroundColor: "#fff",
    borderRadius: "2rem",
    minHeight: "80.5rem",
    padding: "4.6rem 0",
    marginTop: "1.2rem",
  },
  edit_typo: {
    borderRadius: "50%",
    overflow: "hidden",
    height: "19.7rem",
    maxWidth: "19.7rem",
    width: "19.7rem",
    textAlign: "center",
  },

  backArrow: {
    marginRight: "2rem",
    width: "5rem",
    minWidth: "5rem",
    height: "5rem",
    background: "#fff",
    padding: "1.3rem",
    svg: {
      width: "100%",
      height: "100%",
      transform: " rotateY(180deg)",
    },
  },
  UpdateBtn: {
    marginTop: "5rem",
    width: "100%",
    background: "#2165A2",
    borderRadius: "1rem",
    padding: "1.8rem 21.8rem",
    color: "white",
    fontSize: "1.8rem",
    fontWeight: 600,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#2165A2"
    }
  },
};

export default classes;
