import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    manageUserTabIndex: 0,
    clientResourceTabIndex: 0,
    resourceIndex: 0,
    winRateResourceTabIndex: 0
};

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setManageUserTabIndex: (state, action) => {
            state.manageUserTabIndex = action.payload;
        },
        setClientResourceTabIndex: (state, action) => {
            state.clientResourceTabIndex = action.payload;
        },
        setResourceIndex: (state, action) => {
            state.resourceIndex = action.payload;
        },
        setWinRateResourceTabIndex: (state, action) => {
            state.winRateResourceTabIndex = action.payload;
        },
    },
});

export const setManageUserTabIndex = adminSlice.actions.setManageUserTabIndex;
export const setClientResourceTabIndex = adminSlice.actions.setClientResourceTabIndex;
export const setResourceIndex = adminSlice.actions.setResourceIndex;
export const setWinRateResourceTabIndex = adminSlice.actions.setWinRateResourceTabIndex;

const AdminSlice = adminSlice.reducer;
export default AdminSlice;